// background colors
$bg-gray: #f8f8f8;
$bg-white: #ffffff;

// text color
$text-black: #010103;
$text-red: #f85656;
$btn-black: #161616;
$text-gray: #706f7b;

$st-patrick-blue: hsl(236, 57%, 28%);
$amaranth-purple: hsl(335, 88%, 38%);
$royal-blue-dark: hsl(231, 68%, 21%);
$chrome-yellow: hsl(39, 100%, 52%);
$space-cadet-1: hsl(230, 41%, 25%);
$space-cadet-2: hsl(230, 59%, 16%);
$winter-sky_50: hsla(335, 87%, 53%, 0.5);
$purple-navy: hsl(236, 26%, 43%);
$ksu-purple: hsl(275, 54%, 33%);
$winter-sky: hsl(335, 87%, 53%);
$razzmatazz: hsl(335, 87%, 51%);
$platinum: hsl(0, 0%, 90%);
$black_70: hsla(0, 0%, 0%, 0.7);
$rajah: hsl(29, 99%, 67%);
$white: hsl(0, 0%, 100%);

$gradient-1: linear-gradient(90deg, $royal-blue-dark 0, $ksu-purple 51%, $royal-blue-dark);
$gradient-2: linear-gradient(90deg, $razzmatazz , $rajah);