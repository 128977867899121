/**
* border radius
*/

$radius-4: 4px;
$radius-12: 12px;

/**
* spacing
*/

$section-padding: 60px;

/**
* transition
*/

$transition-1: 0.15s ease;
$transition-2: 0.35s ease;
$cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
$cubic-out: cubic-bezier(0.33, 0.85, 0.56, 1.02);

/**
* shadow
*/

$shadow: 0 5px 20px 1px hsla(220, 63%, 33%, 0.1);