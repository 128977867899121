/*
// font family
$titles-font: "Poppins", sans-serif;
$text-font: "Rubik", sans-serif;

// sizes

$h1-size: 5.2rem;
$h2-size: 4.2rem;
$h3-size: 2.4rem;
$h4-size: 2.2rem;
$p-size: 1.6rem;
$input-size: 1.4rem;
*/


// font family
$ff-source-sans-pro: "Source Sans Pro", sans-serif;
$ff-poppins: "Poppins", sans-serif;

// sizes
$fs-1: 4.2rem;
$fs-2: 3.8rem;
$fs-3: 3.2rem;
$fs-4: 2.5rem;
$fs-5: 2.4rem;
$fs-6: 2rem;
$fs-7: 1.8rem;
$fs-8: 1.5rem;

$fw-500: 500;
$fw-600: 600;
$fw-700: 700;