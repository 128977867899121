@use "./colors" as *;
@use "./typography" as *;
@use "./format" as *;

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {list-style: none;}

a {text-decoration: none;}

a,
img,
span,
input,
button { display: block; }

button,
input {
  background: none;
  border: none;
  font: inherit;
}

button { cursor: pointer; }

input { width: 100%; }

img { height: auto; }

address { font-style: normal; }

html {
    font-family: $ff-poppins;
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    background-color: $white;
    color: $purple-navy;
    font-size: 1.6rem;
}

.container {
    padding-inline: 15px;

    @media (min-width: 550px) {
        max-width: 550px;
        margin-inline: auto;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 950px;
    }

    @media (min-width: 1200px) {
        max-width: 1200px;
    }
}

.h2,
.h3 { font-family: $ff-source-sans-pro }

.btn {
  background-image: $gradient-2;
  background-size: 200%;
  color: $white;
  padding: 12px 35px;
  font-size: $fs-8;
  font-weight: $fw-500;
  border-radius: 0 25px;
  transition: $transition-2;
}

.btn:is(:hover, :focus) { background-position: right; }

.w-100 { width: 100%; }

.banner-animation { animation: waveAnim 2s linear infinite alternate; }

@keyframes waveAnim {
  0% { transform: translate(0, 0) rotate(0); }
  100% { transform: translate(2px, 2px) rotate(1deg); }
}

.section { padding-block: $section-padding }

.section-title {
  color: $st-patrick-blue;
  font-size: $fs-3;
  margin-block-end: 60px;
  max-width: max-content;
  margin-inline: auto;

  @media (min-width: 550px) {
    font-size: 3.6rem;
  }

  @media (min-width: 1200px) {
    font-size: 4.6rem;
  }
}

.underline { position: relative; }

.underline::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 6px;
  background-image: $gradient-2;
  border-radius: 10px;
}

:is(.service-card, .features-card) .title {
  color: $st-patrick-blue;
  font-size: $fs-4;
  font-weight: $fw-700;
}

:is(.service-card, .features-card, .blog-card) .text { font-size: $fs-8 }

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

::-webkit-scrollbar { width: 10px; }

::-webkit-scrollbar-track { background-color: hsl(0, 0%, 95%); }

::-webkit-scrollbar-thumb { background-color: hsl(0, 0%, 80%); }

::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }